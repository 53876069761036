// 双层加密   先MD5  再jsencrypt
import md5 from 'js-md5'
import JSEncrypt from 'jsencrypt'
// 数据转为树结构的方法
// pid是子元素的属性名  ID是父元素上的属性名
function translateDataToTree(data, pid, id) {
  // 没有父节点的数据
  let parents = []; let children = []
  parents = data.filter(
    value => value[pid] === 'undefined' || value[pid] == null || value[pid] === ''
  )

  // 有父节点的数据
  children = data.filter(
    value => value[pid] !== 'undefined' && value[pid] != null
  )
  // 定义转换方法的具体实现
  const translator = (parents, children) => {
    // 遍历父节点数据
    parents.forEach(parent => {
      // 遍历子节点数据
      children.forEach((current, index) => {
        // 此时找到父节点对应的一个子节点
        if (current[pid] === parent[id]) {
          // 对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
          const temp = JSON.parse(JSON.stringify(children))
          // 让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
          temp.splice(index, 1)
          // 让当前子节点作为唯一的父节点，去递归查找其对应的子节点
          translator([current], temp)
          // 把找到子节点放入父节点的children属性中
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }

  // 调用转换方法
  translator(parents, children)
  // 返回最终的结果
  return parents
}
// 加密的方法
function encrypt(plaintext) {
  const key =
    'MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBANLpJwrVTmdburKp9khRHBUESLp4KVE+S3umgO7ZaOGtnP0CLvvH8JkpMFJSivTGcpTOAzumevweLhOaeRh3k/69xsSKk2hc/TYGuESWltPta8BQ5xFZxne+e0EOkqWYfrSVEyUaWlgik6EbP2dj5w2fZkZI5QAmAa4vGL/VL89rAgMBAAECgYEAjfofqx4+YjuQqXbacIIRSmCQ6N28ypEJ1RoXzzGRdeIePP88S4ejgicE/VXNbsssFNm9uWjvf0p9r/qbrAnLGEYSoYXm9qOzA6rmU9p1eLW2HaRYCM2jAy9E3wkKCBGzklJm8su9GqXHPRmKCHYMaxSwyvRXJlbsWTsqjRfUtAkCQQD8eWy6DDMtyQowDYGhM+E5f91FHCFuFCVMPxMNahWznJBXTHqi+7GLDx1I6E40WRTJAiCK+VeMwzA/KHfiQ1j9AkEA1dskM6jX2V2eQHxtkoF4NKaTp+xmmzUp3a8uw+SBvKYv+YFN7/sCa8zksyrFarDtUv0WOXvV6uzqfIF24poKhwJAXg5iWgE3g8QUnog5lbN9IW2oIhnzvk2VA42v7FxsD0ixYURln5hCsTqFP2cD026oVqYLN5riaddBfxX1a36JhQJBALuUzctWZYavHFl6Cu6AC/06w7d+n32ygzeFKuL+82GS7+/NoJxIMxZ1kYEqmiF8cZ7iifUAD2JST1fIg4w+C88CQQDTNue1HPVHfpUL0kgz5ivFvl5HRZO/1x0/SNGccGRNWwpt3ryXq72BcgvcCZdwZ0eBLdq4+F61MCYEmg+t7RRY'
  const thisKeyPair = new JSEncrypt({ default_key_size: 1024 })
  if (plaintext instanceof String) {
    // 1、JSON.stringify
    plaintext = JSON.stringify(plaintext)
  }
  thisKeyPair.setPublicKey(key)
  return thisKeyPair.encrypt(md5(plaintext))
}
const timestampToTime = timestamp => {
  const date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}
// 金额向上取整
function roundUpToInteger(money, num = 2) {
  if (!money || !Number(money)) return 0.00
  let digit = 1
  for (let index = 0; index < num; index++) {
    digit += '0'
  }
  return (Math.round((money + Number.EPSILON) * Number(digit)) / Number(digit)).toFixed(num)
}

// 金额 千位逗号分隔符过滤器
function numberToCurrency(value) {
  if (!value) return 0
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理 添加（,）
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = ''
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  // 有小数 获取小数部分
  if (valueArray.length === 2) {
    floatPart = valueArray[1].length < 2 ? valueArray[1].toString() + '0' : valueArray[1].toString()
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + '.' + '00'
}
// 补齐三位小数
function numberThreeDecimal(value) {
  if (!value) return 0.000
  // // 获取整数部分
  const intPart = Math.trunc(value)
  // 预定义小数部分
  let floatPart = ''
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  // 有小数 获取小数部分
  if (valueArray.length === 2) {
    floatPart = valueArray[1].length < 3 && valueArray[1].length > 1 ? valueArray[1].toString() + '0' : valueArray[1].length < 2 ? valueArray[1].toString() + '00' : valueArray[1].toString()
    return intPart + '.' + floatPart
  }
  return intPart + '.' + '000'
}
// 乘法
function floatMul(arg1, arg2) {
  var m = 0; var s1 = arg1.toString(); var s2 = arg2.toString()
  try { m += s1.split('.')[1].length } catch (e) { () => {} }
  try { m += s2.split('.')[1].length } catch (e) { () => {} }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}
// 金额自动补齐N位小数以及加上千分位
function thousandsFractionsSmaller(money, num = 2) {
  if (!money || !Number(money)) return '—'
  let digit = 1
  for (let index = 0; index < num; index++) {
    digit += '0'
  }
  money = (Math.round((money + Number.EPSILON) * Number(digit)) / Number(digit)).toFixed(num)
  var numArry = money.split('.')
  var l = numArry[0].split('').reverse()
  var t = ''
  for (var i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  return t.split('').reverse().join('') + '.' + numArry[1]
}
// 向上取整
function NumberRoundUp(num, byte) {
  const n = Math.pow(10, byte)
  return Math.ceil(num * n) / n
}
// 倒计时函数
function countDownFn(target, returnStr) {
  // 获取当前时间
  var date = new Date()
  // 获取中秋时间
  var tgt = new Date(target)
  // 获取时间差
  var distance = tgt - date
  if (distance <= 0) {
    // alert('目标时间不能小于当前时间')
    return {}
  }

  // 计算剩余时间
  var d = Math.floor(distance / (1000 * 60 * 60 * 24))
  var h = Math.floor((distance / (1000 * 60 * 60)) % 24)
  var m = Math.floor((distance / (1000 * 60)) % 60)
  var ms = Math.floor(distance / 1000 % 60)
  var time =
    d.toString().padStart(2, '0') +
    '天' +
    h.toString().padStart(2, '0') +
    '时' +
    m.toString().padStart(2, '0') +
    '分' +
    ms.toString().padStart(2, '0')

  return !returnStr ? time : { day: d.toString().padStart(2, '0'), hour: h.toString().padStart(2, '0'), minutes: m.toString().padStart(2, '0'), second: ms.toString().padStart(2, '0') }
}
// 排序的函数
function arrSort(arr, label, type) {
  arr.sort((a, b) => {
    if (type === 'asc') {
      return a[label] < b[label] ? -1 : 1
    } else if (type === 'desc') {
      return a[label] > b[label] ? -1 : 1
    }
  })
}

// 获取字典
function getDictName(dictArr = [], dictId = '') {
  if (!dictId || !dictId) return
  const strDictId = String(dictId)
  const dictName = dictArr.find((v) => v.dictId === strDictId).dictName
  return dictName
}

export { arrSort, roundUpToInteger, thousandsFractionsSmaller, encrypt, timestampToTime, translateDataToTree, numberToCurrency, numberThreeDecimal, floatMul, NumberRoundUp, countDownFn, getDictName }
